var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('p', [_vm._v(" The following materials are made available exclusively to APEX Accelerators Staff. These materials are available to support their work as enablers to opening the defense industrial base to small, medium, large, and new entrant companies ")]), _vm._m(1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "alt": "documents thumbnail",
      "src": require("../../assets/images/resources/documents.png")
    },
    on: {
      "click": function () {
        _vm.$router.push('/documents');
      }
    }
  })]), _c('div', {
    staticClass: "col-lg-4"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "alt": "training thumbnail",
      "src": require("../../assets/images/resources/training.png")
    },
    on: {
      "click": function () {
        _vm.$router.push('/training');
      }
    }
  })]), _c('div', {
    staticClass: "col-lg-4"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "alt": "events thumbnail",
      "src": require("../../assets/images/resources/events.png")
    },
    on: {
      "click": function () {
        _vm.$router.push('/events');
      }
    }
  })])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Resources for APEX Accelerators Staff ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Resources")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rows"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "pb-2 text-left"
  }, [_c('p', {
    staticClass: "text-left"
  }, [_c('strong', [_vm._v("Click the box to access the desired resources:")])])])])]);

}]

export { render, staticRenderFns }