import Vue from "vue";
import Router from "vue-router";
import index from "@/components/index";
import about from "@/components/about";
import news from "@/components/news";
import blog from "@/components/blog";
import blogdetail from "@/components/blogdetail";
import newsdetail from "@/components/newsdetail";
import community from "@/components/community";
import dashboard from "@/components/dashboard";
import myaccount from "@/components/my-account";
import pilotregister from "@/components/resource/pilot-register";
import mepregister from "@/components/resource/mep-register";
import contact from "@/components/contact";
import error from "@/components/error";
import verify from "@/components/verify";
import verifymfa from "@/components/verify-mfa";
import newpassword from "@/components/new-password";
import verifyphone from "@/components/verify-phone";
import login from "@/components/login";
import logout from "@/components/logout";
import signup from "@/components/signup";
import cyberreadinesscheck from "@/components/resource/cyber-readiness-check";
import cyberreadinesschecktool from "@/components/resource/cyber-readiness-check-tool";
import cyberreadinesschecktraining from "@/components/resource/cyber-readiness-check-training";
import toollist from "@/components/resource/tool-list";
import tooldetails from "@/components/resource/tool-details";
import toolcompare from "@/components/resource/tool-compare";
import whitepapers from "@/components/resource/white-papers";
import whitepaperdetail from "@/components/resource/white-paper-detail";
import cybercircuitsdetail from "@/components/resource/cyber-circuits-detail";
import eventdetail from "@/components/resource/event-detail";
import events from "@/components/resource/events";
import aboutcmmc from "@/components/resource/about-cmmc";
import aboutus from "@/components/resource/about-us";
import serviceAreaLocator from "@/components/resource/service-area-locator.vue";
import onlinecourses from "@/components/resource/online-courses";
import trainingvideos from "@/components/resource/training-videos";
import coursesencite from "@/components/courses-encite";
import pilotprogram from "@/components/resource/pilot-programs";
import sba from "@/components/resource/small-business-administration";
import ps from "@/components/resource/project-spectrum";
import partners from "@/components/resource/partners-landing";
import pricing from "@/components/pricing";
import policycorner from "@/components/policycorner";
import mep from "@/components/resource/manufacturing-extension-partnership";
import documents from "@/components/resource/documents";
import training from "@/components/resource/training";
import trainingdetail from "@/components/resource/trainingDetail";
import surveys from "@/components/resource/surveys";
import networkTrainingFeedback from "@/components/resource/surveys/network-training-feedback";
import projectSpectrumFeedback from "@/components/resource/surveys/project-spectrum-feedback";
import DOTBusiness from "@/components/resource/surveys/DOT-how-to-do-business";
import HHSBusiness from "@/components/resource/surveys/HHS-how-to-do-business";
import EventFeedbackSurvey from "@/components/resource/surveys/event-feedback-survey";
import leadership from "@/components/resource/leadership";
import resources from "@/components/resource/resources";
import speakerrequest from '@/components/speaker-request';
import knowledgeCheckHHS from '@/components/resource/surveys/knowledge-check-HHS';
import feedback from "@/components/feedback";
import store from "@/store";
import axios from "axios";
import {} from "@/utils/authentication";
import {
  isLoggedIn,
  getAreAccountRequiredFieldsFilled,
} from "../utils/authentication";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      // /search/screens -> /search?q=screens
      path: "/!",
      redirect: (to) => {
        // the function receives the target route as the argument
        // we return a redirect path/location here.
        return { path: "/" };
      },
    },
    {
      // /search/screens -> /search?q=screens
      path: "/#!/register",
      redirect: (to) => {
        // the function receives the target route as the argument
        // we return a redirect path/location here.
        return { path: "/signup" };
      },
    },
    {
      // /search/screens -> /search?q=screens
      path: "/!/register",
      redirect: (to) => {
        // the function receives the target route as the argument
        // we return a redirect path/location here.
        return { path: "/signup" };
      },
    },
    {
      // /search/screens -> /search?q=screens
      path: "/#!/eventRegistration/:id",
      redirect: (to) => {
        // the function receives the target route as the argument
        // we return a redirect path/location here.
        return { path: "/event-detail", query: { id: to.params.id } };
      },
    },
    {
      // /search/screens -> /search?q=screens
      path: "/!/eventRegistration/:id",
      redirect: (to) => {
        // the function receives the target route as the argument
        // we return a redirect path/location here.
        return { path: "/event-detail", query: { id: to.params.id } };
      },
    },
    {
      path: "/",
      name: "index",
      component: index,
    },
    {
      path: "/about",
      name: "about",
      component: about,
    },
    {
      path: "/default/dashboard/organization",
      name: "dashboard",
      component: dashboard,
    },
    {
      path: "/news",
      name: "news",
      component: news,
      props: (route) => ({ query: route.query.query }),
    },
    {
      path: "/community",
      name: "community",
      component: community,
    },
    {
      path: "/blog",
      name: "blog",
      component: blog,
      props: (route) => ({ query: route.query.query }),
    },
    {
      path: "/blogdetail",
      name: "blogdetail",
      component: blogdetail,
      props: (route) => ({ id: route.query.id }),
    },
    {
      path: "/newsdetail",
      name: "newsdetail",
      component: newsdetail,
      props: (route) => ({ id: route.query.id }),
    },
    {
      path: "/verify",
      name: "verify",
      component: verify,
      props: (route) => ({
        email: route.query.email,
        message: route.query.message,
        accountType: route.query.accountType,
      }),
    },
    {
      path: "/verify-mfa",
      name: "verify-mfa",
      component: verifymfa,
      props: (route) => ({
        session: route.query.session,
        username: route.query.username,
        redirect: route.query.redirect,
      }),
    },
    {
      path: "/new-password",
      name: "new-password",
      component: newpassword,
      props: (route) => ({
        session: route.query.session,
        username: route.query.username,
        redirect: route.query.redirect,
      }),
    },
    {
      path: "/verify-phone",
      name: "verify-phone",
      component: verifyphone,
      props: (route) => ({ phone: route.query.phone }),
    },
    {
      path: "/login",
      name: "login",
      component: login,
      props: (route) => ({ redirect: route.query.redirect }),
    },
    {
      path: "/logout",
      name: "logout",
      component: logout,
    },
    {
      path: "/signup",
      name: "signup",
      component: signup,
    },
    {
      path: "/my-account",
      name: "my-account",
      component: myaccount,
    },
    {
      path: "/pilot-register",
      name: "pilot-register",
      component: pilotregister,
    },
    {
      path: "/mep-register",
      name: "mep-register",
      component: mepregister,
    },
    {
      path: "/contact",
      name: "contact",
      component: contact,
    },
    {
      path: '/speaker-request',
      name: 'speakerrequest',
      component: speakerrequest,
    },
    {
      path: "/feedback",
      name: "feedback",
      component: feedback,
    },
    {
      path: "/cyber-readiness-check",
      name: "cyber-readiness-check",
      component: cyberreadinesscheck,
      props: true,
    },
    {
      path: "/cyber-readiness-check-tool/:toolKey",
      name: "cyber-readiness-check-tool",
      component: cyberreadinesschecktool,
      props: (route) => ({ query: route.query.query }),
    },
    {
      path: "/cyber-readiness-check-training",
      name: "cyber-readiness-check-training",
      component: cyberreadinesschecktraining,
    },
    {
      path: "/tool-list",
      name: "tool-list",
      component: toollist,
    },
    {
      path: "/tool-details",
      name: "tool-details",
      component: tooldetails,
      props: (route) => ({ id: route.query.id }),
    },
    {
      path: "/tool-compare",
      name: "tool-compare",
      component: toolcompare,
      props: (route) => ({ ids: route.query.ids }),
    },
    {
      path: "/white-papers",
      name: "white-papers",
      component: whitepapers,
      props: (route) => ({ query: route.query.query }),
    },
    {
      path: "/white-paper-detail",
      name: "white-paper-detail",
      component: whitepaperdetail,
      props: (route) => ({ id: route.query.id }),
    },
    {
      path: "/events",
      name: "events",
      component: events,
      props: (route) => ({ query: route.query.query }),
    },
    {
      path: "/cyber-circuits-detail",
      name: "cyber-circuits-detail",
      component: cybercircuitsdetail,
      props: (route) => ({ id: route.query.id }),
    },
    {
      path: "/event-detail",
      name: "event-detail",
      component: eventdetail,
      props: (route) => ({ id: route.query.id }),
    },
    {
      path: "/about-cmmc",
      name: "about-cmmc",
      component: aboutcmmc,
    },
    {
      path: "/about-us",
      name: "about-us",
      component: aboutus,
    },
    {
      path: "/service-area-locator",
      name: "service-area-locator",
      component: serviceAreaLocator,
    },
    {
      path: "/online-courses",
      name: "online-courses",
      component: onlinecourses,
    },
    {
      path: "/training-videos",
      name: "training-videos",
      component: trainingvideos,
      props: (route) => ({ query: route.query.query }),
    },
    {
      path: "/courses-encite",
      name: "courses-encite",
      component: coursesencite,
      props: (route) => ({ query: route.query.query }),
    },
    {
      path: "/pilot-program",
      name: "pilot-program",
      component: pilotprogram,
    },
    {
      path: "/small-business-administration",
      name: "small-business-administration",
      component: sba,
    },
    {
      path: "/project-spectrum",
      name: "project-spectrum",
      component: ps,
    },
    {
      path: "/partners",
      name: "partners",
      component: partners,
    },
    {
      path: "/mep",
      name: "mep",
      component: mep,
    },
    {
      path: "/documents",
      name: "documents",
      component: documents,
      props: (route) => ({ query: route.query.query }),
    },
    {
      path: "/training",
      name: "training",
      component: training,
      props: (route) => ({ query: route.query.query }),
    },
     {
      path: "/training-detail",
      name: "trainingDetail",
      component: trainingdetail,
      props: (route) => ({ query: route.query.query }),
    },
    {
      path: "/surveys",
      name: "surveys",
      component: surveys,
      props: (route) => ({ query: route.query.query }),
    },
    {
      path: "/surveys/national-security-innovation-network-training-feedback",
      name: "networkTrainingFeedback",
      component: networkTrainingFeedback
    },
    {
      path: "/surveys/introduction-to-project-spectrum-training-feedback",
      name: "projectSpectrumFeedback",
      component: projectSpectrumFeedback
    },
    {
      path: "/surveys/how-to-do-to-business-with-DOT",
      name: "DOTBusiness",
      component: DOTBusiness
    },
    {
      path: "/surveys/how-to-do-to-business-with-HHS",
      name: "HHSBusiness",
      component: HHSBusiness
    },
    {
      path: "/surveys/knowledge-check-HHS",
      name: "knowledgeCheckHHS",
      component: knowledgeCheckHHS
    },
    {
      path: "/surveys/event-feedback-survey",
      name: "EventFeedbackSurvey",
      component: EventFeedbackSurvey
    },
    {
      path: "/leadership",
      name: "Leadership",
      component: leadership,
      props: (route) => ({ query: route.query.query }),
    },
    {
      path: "/resources",
      name: "Resources",
      component: resources
    },
    {
      path: "/pricing",
      name: "pricing",
      component: pricing,
    },
    {
      path: "/policy-corner",
      name: "policy-corner",
      component: policycorner,
    },
    {
      path: "*",
      name: "error",
      component: error,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeResolve(async (to, from, next) => {
  console.log("Nav To", to.fullPath);
  /*if (isLoggedIn()) {
    axios
      .post('/api/spectranalytics/authenticated', {
        event: 'url',
        description: to.fullPath,
      })
      .then(() => {
        console.log('Tracked User Activity');
      })
      .catch((error) => {
        handleApiError(error, null);
      });

    const pagesNotRequiringFilledFields = ['/my-account', '/logout'];
    if (
      !getAreAccountRequiredFieldsFilled() &&
      !pagesNotRequiringFilledFields.includes(to.fullPath)
    ) {
      next('/my-account');
    }
  } else {
    axios
      .post('/api/spectranalytics/anonymous', {
        event: 'url',
        description: to.fullPath,
      })
      .then(() => {
        console.log('Tracked Anonymous Activity');
      })
      .catch((error) => {
        console.log(error);
      });
  }*/
  return next();
});

//  Use this function to generate dynamical route list for sitemap.
// function getRoutesList(routes, pre) {
//    const routesList =  routes.reduce((array, route) => {
//       const path = `${pre}${route.path}`;

//       if (route.path !== '*') {
//         array.push(path);
//       }

//       if (route.children) {
//         array.push(...getRoutesList(route.children, `${path}/`));
//       }

//       return array;
//     }, []);
//     //console.log(routesList);
// }
// getRoutesList(router.options.routes, '');

export default router;
