<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div class="col-md-12 justify-content-center align-self-center pb-2">
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                        Resources for APEX Accelerators Staff
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Resources</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Grid Section Start -->
    <section class="section">
      <div class="container">
        <p>
            The following materials are made available exclusively to APEX Accelerators Staff. These
materials are available to support their work as enablers to opening the defense industrial
base to small, medium, large, and new entrant companies
        </p>
        <div class="rows">
          <div class="col-md-6">
            <div class="pb-2 text-left">
              <p class="text-left"><strong>Click the box to access the desired resources:</strong> </p>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <img 
                  alt="documents thumbnail" 
                  class="img-fluid" 
                  src="../../assets/images/resources/documents.png" 
                  @click="() => { $router.push('/documents') }"
                />
            </div>
            <div class="col-lg-4">
              <img 
                alt="training thumbnail" 
                class="img-fluid" 
                src="../../assets/images/resources/training.png" 
                @click="() => { $router.push('/training') }"
              />
            </div>
            <div class="col-lg-4">
              <img 
                alt="events thumbnail" 
                class="img-fluid" 
                src="../../assets/images/resources/events.png" 
                @click="() => { $router.push('/events') }"
              />
            </div>
        </div>
      </div>
    </section>
    
    <!-- Blog Grid Section End -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';
export default {
  name: 'blog',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      searchQuery: this.query,
      items: [],
      itemCount: 0,
      currentPage: 1,
      perPage: 6,
      bucket: '',
      selectedFolder: '',
      trainingCategories: [],
      trainingCategoriesFiltered: [],
      queryCategory: '',
      queryOverallCategory: '',
      queryTag: ''
    };
  },
  computed: {
    trainingCategoriesFilteredCount() {
      return this.trainingCategoriesFiltered.length
    },
    trainingCategoriesFilteredVisible() {
      return this.trainingCategoriesFiltered.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    }
  },
  methods: {
    setTag(tag) {
      console.log("you want to set the tag");
      this.queryTag = tag;
    },
    setOverallCategory(overallCategory) {
      this.queryOverallCategory = overallCategory;
    },
    async getTrainingCategories() {
      axios
        .get('/api/resources/training/categories')
        .then((response) => {
          this.items = response.data;
          this.trainingCategories = response.data;
          this.trainingCategoriesFiltered = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    pageClicked() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    filterTrainingCategories() {

      this.currentPage = 1;

      this.trainingCategoriesFiltered = this.items.filter((item) => {
          
        let matchesFound = [];

        // check category
        if(this.queryCategory && item.category.toLowerCase().includes(this.queryCategory.toLowerCase())) {
          matchesFound.push("category found");
        }

        // check tag
        if(item.tag.toLowerCase() == this.queryTag.toLowerCase()) {
          matchesFound.push("tag found");
        }

        if(item.overallCategory.toLowerCase() == this.queryOverallCategory.toLowerCase()) {
          matchesFound.push("overall category found");
        }

        return matchesFound.length > 0;

      });

    }
  },
  watch: {
    queryCategory() {
      this.filterTrainingCategories()
    },
    queryOverallCategory() {
      this.filterTrainingCategories()
    },
    queryTag() {
      this.filterTrainingCategories()
    }
  },
  mounted: async function () {
    await this.getTrainingCategories(); // async
  },
};
</script>
<style scoped>
#blog-pagination li {
  margin: 0px;
}

.useful-link-info {
  padding: 10px 5px 25px 0;
}

.thumbnail-cover {
  object-fit: cover;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  /* padding: 10px; */
}

.center-image-vertically {
  display: flex;
  justify-content: center;
}

.img-fluid {
  cursor: pointer;
}


.list-group-item {
  border: 1px solid transparent !important;
  display: flex;
  align-items: center;  
}

.list-group-image-wrapper {
  height: 60px;
  width: 60px;
  display: flex;
  flex-shrink: 0;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}

.list-group-item:hover, .list-group-item:focus {
  border: 1px solid rgba(0, 0, 0, 0.125)  !important;
}
</style> 